.PackageDisplay {
  //position: 'absolute',
  //top: '50%',
  //left: '50%',
  //transform: 'translate(-50%, -50%)',
  //width: 400,
  //bgcolor: 'background.paper',
  //border: '2px solid #000',
  //boxShadow: 24,
  //p: 4,
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: red;
  border-radius: 4px;
  box-shadow: 10px 10px 10px #0b2e13;
}